export const portfolios = [
  {
    id: 1,
    title: 'our Services',
    description: 'Project description',
    category: 'Main',
    meta: [
      {
        id: 1,
        type: 'Date',
        text: '28 Sep 2020',
      },
    ],
  },
  {
    id: 2,
    category: 'Services',
    title: 'Integration',
    description:
      '<p>We do web and mobile integration to any API of your choice. or on an app? Where do you start? If you have a hard time choosing what to read and where to read it, you’re not alone, because the choices are limitless. With print and web and digital options, we now have so many comic books. (Source: howdesign.com)</p> <p>Marketing has always been about connecting with your audience in the right place and at the right time. Today, that means you need to meet them where they are already spending time: on the internet. (Source: blog.hubspot.com)</p>',
    meta: [],
    thumbnails: ['01.jpg', '03.jpg'],
  },
  {
    id: 3,
    title: 'Payments & Collections',
    description:
      '<p>We handle web payments, bank collections and financial technology services</p>',
    category: 'Services',
  },
  {
    id: 4,
    title: 'Support',
    description:
      '<p>Technology support for all your technology projects. Free u your team to handle your core business, let us handle the technology support aspect of your business </p>',
    category: 'Service',
  },
  {
    id: 5,
    title: 'Web Portals',
    description:
      '<p>Modern web portals for schools, organization and society </p>',
    category: 'Services',
    meta: [
      {
        id: 1,
        type: 'Date',
        text: '28 Sep 2020',
      },
    ],
  },
  {
    id: 6,
    title: 'Hardware & Devices',
    description: '',
    category: 'Hardware',
    meta: [
      {
        id: 1,
        type: 'Date',
        text: '28 Sep 2020',
      },
    ],
  },
  {
    id: 7,
    title: 'Financial Inclusion',
    description:
      '<p>Lets bank the unbanked. We have the technology that brings financial services to every remote and urban region of any economy </p>',
    category: 'Service',
  },
  {
    id: 8,
    title: 'Micro Lending',
    description:
      '<p>Small businesses helping small businesses grow. Safe and secure micro lending using state of the art technology that guarantees returns on investment </p>',
    category: 'Service',
  },
  {
    id: 9,
    title: 'Procurement',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
  {
    id: 10,
    title: 'License',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
  {
    id: 11,
    title: 'Cloud',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
  {
    id: 12,
    title: 'Training',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
  {
    id: 13,
    title: 'Product Management',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
  {
    id: 14,
    title: 'Recruitment',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
  {
    id: 15,
    title: 'Technology Management',
    description:
      '<p>Hardware procurement should not give you sleepless nights. </p>',
    category: 'Hardware',
  },
]
