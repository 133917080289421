import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helper/scrollToTop";
import WOW from "wowjs";
import BlogDetails from "./pages/blog/BlogDetails";
import BlogWithoutSidebar from "./pages/blog/BlogWithoutSidebar";
import Portfolio from "./pages/portfolios/PortfolioDetailsModern";
import Error from "./pages/others/Error";
import Career from "./pages/others/Career";
import ComingSoon from "./pages/others/ComingSoon";
import Maintenance from "./pages/others/Maintenance";
import TeamCarousel from "./pages/team/TeamCarousel";
import TeamGrid from "./pages/team/TeamGrid";
import PricingTableTwo from "./pages/pricing/PricingTableTwo";
import PricingTableOne from "./pages/pricing/PricingTableOne";
import ContactClassic from "./pages/contact/ContactClassic";
import ServiceModern from "./pages/service/ServiceModern";
import ServiceClassic from "./pages/service/ServiceClassic";
import AboutPageOne from "./pages/about/AboutPageOne";
import HomeDigitalAgency from "./pages/homes/HomeDigitalAgency";
import ErrorBoundary from "./container/ErrorBoundary";

const App = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-details/:bid"}`}
              component={BlogDetails}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/blog"}`}
              component={BlogWithoutSidebar}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/portfolio/:pid"}`}
              component={Portfolio}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/career"}`}
              component={Career}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
              component={ComingSoon}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/maintenance"}`}
              component={Maintenance}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/team-carousel"}`}
              component={TeamCarousel}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/team"}`}
              component={TeamGrid}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/pricing-table-two"}`}
              component={PricingTableTwo}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/pricing-table"}`}
              component={PricingTableOne}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/service-modern"}`}
              component={ServiceModern}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/service-classic"}`}
              component={ServiceClassic}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              component={ContactClassic}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about-one"}`}
              component={AboutPageOne}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/home-digital-agency"}`}
              component={HomeDigitalAgency}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              component={HomeDigitalAgency}
            />
            <Route exact component={Error} />
          </Switch>
        </ScrollToTop>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
