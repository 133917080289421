import React, {Component} from 'react';


class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.state = {
            hasError: false
        }
    }
    static getDerivedStateFromError(){
        return { hasError: true}
    }
    componentDidCatch(error, info) {
        console.error("error boundary caught", error, info);
      }
      render() {
        if (this.state.hasError) {
          return <div><p>Error Occurred.</p></div>;
        }
        return this.props.children;
      }
}


export default ErrorBoundary;