import React from "react";
import Disqus from "disqus-react";
import { url } from "../../../util/config";
const Comments = () => {
  const disqusShortname = "dtics";
  const disqusConfig = {
    url,
    identifier: "dtics blog",
    title: " Article",
  };
  return (
    <div className="comments-wrapper">
      <div className="inner">
        <h4 className="heading heading-h4"> Comments</h4>

        <div className={`comment-list-wrap mt--25`}>
          <Disqus.DiscussionEmbed
            shortname={disqusShortname}
            config={disqusConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default Comments;
