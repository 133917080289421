import React from 'react';
import {Container} from "react-bootstrap";
import blogData from "../../../data/blog"
import SidebarLayout from "./SidebarLayout";
import WithOutSidebarLayout from "./WithOutSidebarLayout";

const BlogContainer = ({sidebar, sidebarPosition}) => {
    return (
        <div className="bk-blog-grid-area bg_color--5 section-ptb-150">
            <Container>
                {sidebar ? (
                    <SidebarLayout data={data} sidebarPosition={sidebarPosition}/>
                ) : (
                    <WithOutSidebarLayout data={data}/>
                )}
            </Container>
        </div>
    );
};

const data = [
    ...blogData,
    
]

export default BlogContainer;
