import React from 'react';
import MapItem from "../../../components/map/MapItemOne";

const MapOne = () => {
    return (
        <div className="google-map-area">
            <div className="position-relative" style={{height: '570px'}}>
                <MapItem latitude="38.85081" longitude="-77.35545"/>
            </div>
        </div>
    );
};

export default MapOne;
