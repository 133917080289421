import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderEight";
import ContactInfo from "../../container/contact/ContactInfoOne";
import ContactForm from "../../container/contact/ContactFive";
import Footer from "../../container/footer/FooterOne";
import MapOne from '../../container/maps/homes/MapOne';

const ContactClassic = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Contact Us | DTICS</title>
            </Helmet>

            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>               
                <MapOne/>
                <ContactInfo/>
                <ContactForm/>
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default ContactClassic;