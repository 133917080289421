import React from 'react';

const Input = ({type, name, id, placeholder, required, value, className, ref}) => {
    return (
        type === 'textarea' ? (
            <textarea id={id} name={name} placeholder={placeholder} required={required} className={className} ref={ref}/>
        ) : (
            <input
                type={type}
                id={id} name={name}
                placeholder={placeholder}
                value={value && value}
                required={required}
                ref={ref}
                className={className}
            />
        )
    );
};

export default Input;