import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import LayoutDefault from '../../layouts/LayoutDefault'
import Header from '../../components/header/HeaderTwo'
import Footer from '../../container/footer/FooterOne'
import PortfolioDetails from '../../container/portfolio-details/ModernPortfolioDetails'
import { useParams } from 'react-router-dom'
import { portfolios } from '../../data/portfolio-details/mock'

const Portfolio = () => {
  const pid = useParams()
  const pdt = portfolios.find((itm) => itm.id === +pid.pid)
  return (
    <Fragment>
      <Helmet>
        <title>Portfolio DTICS || </title>
      </Helmet>
      <LayoutDefault className="template-color-1 template-font-1">
        <Header />
        {pdt && <PortfolioDetails pdt={pdt} />}
        <Footer />
      </LayoutDefault>
    </Fragment>
  )
}

export default Portfolio
