import React from "react";
import { Col, Row } from "react-bootstrap";
import Thumbnail from "../../../components/about-us/thumbnail/AboutThumbOne";
import thumbs1 from "../../../assets/img/blog/big-img/blog-1.png";

const Article = ({ blog }) => {
  return (
    <article className="blog-post standard-post">
      <header className="header mb--40 text-center">
        <h3 className="heading heading-h3 font-32">{blog.title}</h3>
        <div className="post-meta mt--20">
          <div className="post-date">{blog?.meta?.postDate}</div>
          <div className="post-category">
            <a href={`${process.env.PUBLIC_URl + "/"}`}>
              {blog?.meta?.category}
            </a>
          </div>
        </div>
      </header>

      <Thumbnail thumb={thumbs1} className="mb--60" />

      <section className="content basic-dark2-line-1px pb--50 mb--35">
        <div className="inner">
          <h5 className="heading heading-h5 line-height-1-95 wow move-up">
            “Let us confidently walk together in the direction of your dreams.”
          </h5>
          <div className="desc mt--45 mb--50">
            <p className="bk_pra wow move-up">
              While 70% of websites are built by developers, agencies, and
              freelancers, only 3% of websites are built by small-businesses
              with self-serve DIY tools like Squarespace, Wix, and Weebly. The
              DIY model has barely putting a dent in the website building
              market, despite aggressively building out features for over a
              decade.
            </p>
          </div>
        </div>

        <Thumbnail
          thumb={require("../../../assets/img/blog/architecture/" + blog.thumb)}
        />

        <div className="desc mt--45">
          <p className="bk_pra wow move-up">
            Why do businesses still depend on internal teams or service
            providers, ie agencies and freelancers, to build websites for them?
            Can’t these feature-rich self-serve platforms provide powerful,
            easy-to-use tools businesses need to build a site themselves,
            without needing to depend on “the web guy?” Shouldn’t building a
            website be as easy as setting up a Facebook.
          </p>
        </div>

        <div className="bk-quote-content">
          <blockquote className="brook-quote move-up wow">
            <div className="quote-text">
              Platform companies are product organizations that think in terms
              of roadmaps and features and sprints.
            </div>
          </blockquote>
        </div>

        <div className="desc mt--45">
          <p className="bk_pra wow move-up">
            While some websites are simple and any luddite can quickly build
            them with existing DIY tools, many business websites are extremely
            complex and are custom built by internal teams and / or digital
            agencies for hundreds of thousands of dollars. Or millions. And,
            many small businesses just don’t want to learn how to use DIY tools,
            or don’t care to, or have time to. They’d rather focus on their day
            to day business. Digital marketing is not their forte or interest.
            (Source: medium.com)
          </p>
        </div>
      </section>

      <footer className="blog-footer mb--85 wow move-up">
        <Row>
          <Col lg={12}>
            <div className="post-tag d-flex justify-content-center align-items-center">
              <h6 className="heading heading-h6">TAGS:</h6>
              <div className="blog-tag-list pl--5">
                <a href={`${process.env.PUBLIC_URL + "/"}`}>business</a>
                <a href={`${process.env.PUBLIC_URL + "/"}`}>format</a>
              </div>
            </div>
          </Col>
        </Row>
      </footer>
    </article>
  );
};

export default Article;
