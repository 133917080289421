import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderEight";
import BlogDetailsContent from "../../container/blog-details";
import Footer from "../../container/footer/FooterOne";
import blogData from "../../data/blog";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const bid = useParams();
  const s_blog = blogData.find((itm) => itm.id === +bid.bid);

  return (
    <Fragment>
      <Helmet>
        <title>Blog Details || DTICS</title>
      </Helmet>
      <LayoutDefault className="template-color-1 template-font-1">
        <Header />
        <BlogDetailsContent sidebar={true} blog={s_blog} />
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};

export default BlogDetails;
