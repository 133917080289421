import React, { useEffect, useState } from 'react';
import {Col, Row} from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { submitContact } from '../../data/api/contact';
import MessageBoxItem from "../../components/message-box";

const ContactFormOne = () => {
    const [msg,setMsg] = useState(null);
    const {register,errors,reset, handleSubmit} = useForm();
    useEffect(() => {
        let timeoutId;
        if(msg){
             timeoutId = msg && setTimeout(() => setMsg(null), 6000);
            console.log(timeoutId, "my time out is called");
        }
        
        return clearTimeout(timeoutId);        
    },  [msg]);
    const _submit = (e) => {
        submitContact(e);
        setMsg("form submitted, a member of staff will contact you shortly. Thank you")
        reset();       
    }
    return (
        <div className="contact-form">
            <form onSubmit={handleSubmit(_submit)}>
                <Row>
                    <Col xs={12}>
                        {msg && <MessageBoxItem
                            type={'success'}
                            content={msg}
                        /> }                    
                        </Col>
                    <Col xs={12}>
                        <span>{errors?.name?.message}</span>
                        <span>{errors?.name?.type === 'maxLength' && 'Name is too long'}</span>
                        <span>{errors?.name?.type === 'minLength' && 'Name is too short'}</span>
                    <input id={'name'}
                            name={'name'}
                            type={'text'}
                            placeholder={'Name*'}
                            ref={register({ required: 'Name is required!', maxLength: 40, minLength: 5 })}
                           />
                      
                    </Col>

                    <Col xs={12} className="mt--30">
                        <span>{errors?.email?.message}</span>
                        <input id={'email'}
                            name={'email'}
                            type={'text'}
                            placeholder={'Email*'}
                           
                            ref={register({ required: true, pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: 'Invalid email',
                              }})}
                            />
                       
                    </Col>

                    <Col xs={12} className="mt--30">
                        {errors?.phone?.type === 'maxLength' && 'Phone is too long!'}
                        <input id={'phone'}
                            name={'phone'}
                            type={'text'}
                            placeholder={'Phone Number'}
                            ref={register({maxLength: 15})}
                             />
                       
                    </Col>

                    <Col xs={12} className="mt--30">
                        <span>{errors?.message?.type === 'required' && "Message is required!"}</span>
                    <textarea id="message"
                            name='message' placeholder={'Your Message*'} className="" ref={register({required: true})}/>
                      
                    </Col>

                    <Col xs={12} className="mt--30 text-center">
                        <input
                            id={'submit_btn'}
                            name={'submit_btn'}
                            type={'submit'}
                            value={'Send Message'}
                            className=""
                            
                        />
                      
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default ContactFormOne;
