
export const postLambda = (obj) => {
    return {
      method: "POST",
      headers: {
        Authorization: "Basic bW9kZXNpcmU6bW9yYXlvMDQxNzIw",
        Accept: "application/json",
        "Content-Type": "application/json",
       
      },
      body: JSON.stringify(obj),
    };
  };
export async function submitContact(obj){
    const tosend = {cust:obj.name, to: ['babaphemy@yahoo.com','inquiries@dtics.com'], from: "webmaster@myeverlasting.net", title: "Contact Us", message: JSON.stringify(obj)}
    try {
        const response = await fetch(
          "https://xtz05mugj7.execute-api.eu-west-1.amazonaws.com/dev/sendemail",
          postLambda(tosend)
        );
        const json = await response.json();
        return { data: json };
      } catch (error) {
        return { error: error };
      }

}